import { BASE_PATH } from "@/generated/base";
import { useViewMode } from "@/hooks/useViewMode";
import globalAxios from "axios";
import buildQuery from "odata-query";
import { message } from "./common";

// const server_url = location.origin + '/api/standard';
// const server_url = import.meta.env.VITE_SERVER_URL;
const { isPc } = useViewMode();

function errorHandler(res: any) {
  const data = res.data;
  let errMsg: string | undefined;

  // odata 数据返回不完整
  if (/@odata/.test(data)) {
    errMsg = "odata 返回数据不完整";
  } else if (data.code === "Fail") {
    errMsg = data.message;
  } else if (data?.data?.errmsg) {
    errMsg = data?.data?.errmsg;
  }

  if (errMsg) {
    // alert(errMsg);
    console.log("errMsg: ", errMsg);
    // message.error(errMsg);
    return data?.data;
    throw new Error(errMsg);
  }
}
let Inc = new Date().getTime();
globalAxios.interceptors.request.use((config: any) => {
  config.headers['Access-Control-Allow-Origin'] = '*';
  // console.log(config, "config");

  // if (server_url) {
  //   config.url = config.url?.replace(BASE_PATH, server_url);
  // }
  // Inc += 1;
  // if (config.params) {
  //   config.params._ = Inc;
  // } else {
  //   config.params = {
  //       _: Inc
  //   };
  // }
  if (config?.odata) {
    if (typeof config?.odata === "object") {
      config.odata = buildQuery(config?.odata);
    }
    config.url += config?.odata;
  }
  if (window.location.origin.indexOf('localhost') == -1) {
    // config.url = import.meta.env.VITE_SERVER_ORIGIN + config.url;
    config.url = window.location.origin + config.url;
  }
  // console.log(config, import.meta.env.VITE_SERVER_URL, "config after");

  return config;
});

// 返回拦截
globalAxios.interceptors.response.use(
  (res: any) => {
    errorHandler(res);
    // console.log("response: ", res);
    // odata返回数据
    if (res.data["@odata.context"]) {
      if (res.data.value) {
        if (res.data["@odata.count"] > -1) {
          res.count = res.data["@odata.count"];
        }
        res.resData = res.data;
        res.data = res.data.value;
      } else {
        delete res.data["@odata.context"];
      }
    } else {
      // 其他返回
      res.count = res.data["data_count"];
      res.resData = res.data;
      // res.data = res.data.data;
    }
    // if (res.data["data_count"] > -1) {
    // }
    return res;
  },
  (err) => {
    console.error(err, "reject err");
    // alert(
    //   err.config?.url +
    //     ":   " +
    //     err.message +
    //     " " +
    //     err.response.data?.slice(0, 100)
    // );
    // throw new Error(err);
    return err;
  }
);
